<template>
  <div @click="openWin" class="symptom_select_input">
    <div class="text" v-if="value.length">
      <span
        class="symptom_name"
        v-for="item in value"
        :key="'dsId_' + item.dsId"
      >
        <symptomChanges :symptomInfo="item">
          {{ item.dsName }}
        </symptomChanges>
      </span>
    </div>
    <div v-else class="text placeholder">
      {{ placeholder }}
    </div>

    <Modal
      class="condition symptom_select_input"
      fullscreen
      v-model="showWin"
      title="病历症状录入"
      footer-hide
    >
      <template slot="header">
        <div class="ivu-modal-header-inner">病历症状录入</div>
        <div class="pathent-info">
          <i class="iconfont icon-ren"></i>
          <span class="pathent-info-name">{{ info.patientName }}</span>
          <span class="pathent-info-sex">
            <Icon v-if="info.sex == '男'" type="md-male" />
            <Icon v-else type="md-female" />
            {{ info.sex }}
          </span>
          <span class="pathent-info-age">{{ info.patientAge }}岁</span>
          <span class="pathent-info-cellphoneNo">{{
            common.phone(info.cellphoneNo)
          }}</span>
        </div>
        <div class="btn_group">
          <Button @click="close">退出</Button>
          <Button type="primary" @click="send">{{
            filterDtId ? "保存" : "确认选择"
          }}</Button>
        </div>
      </template>
      <symptom-select
        sort
        :filterDtId="filterDtId"
        :quote="quote"
        ref="symptomSelect"
        :sexAndAge="sexAndAge"
        v-model="selectList"
        @clickItem="clickItem2"
        @clickMenu="clickMenu"
      />
      <div class="s_s_i_list">
        <div class="s_s_i_list_body">
          <div class="left" v-if="selectEtiology">
            <etiologySelect
              ref="etiologySelect"
              :info="info"
              :symptomInfo="activeSymptom2"
              @change="etiologySelectChange"
              :etiologyIds="etiologyIds"
            />
          </div>
          <div class="right">
            <!-- <div class="s_header2">
              <div>
                <div>未选病因症状: <span class="s_header2_num">{{notSelected.length}}个</span></div>
                <div>已选病因症状: <span class="s_header2_num">{{selected.length}}个</span></div>
              </div>
              <div>
                <i-switch v-model="showMore" size="small" />
              </div>
            </div> -->
            <template v-if="!showMore">
              <div class="s_body">
                <Tabs value="name1">
                  <TabPane label="已选症状" name="name1"></TabPane>
                  <TabPane label="" name="name2" disabled></TabPane>
                </Tabs>
                <div v-if="!filterDtId" class="title_right">
                  <Button
                    @click="showLastSymoptom = !showLastSymoptom"
                    size="small"
                    >上次症状</Button
                  >
                  <Button @click="clear" size="small">清空症状</Button>
                </div>
                <span v-for="item in selectList" :key="item.dsId">
                  <symptomDropdown
                    :symptomInfo="item"
                    @clickMenu="clickMenu"
                    parentClass=".right .s_body"
                  >
                    <!-- class="symptom_name symptom_name_remove" -->
                    <span
                      class="symptom_name"
                      :class="{ isError: item.active }"
                      @click="clickMenu(item, 3)"
                      @dblclick="clickMenu(item, 4)"
                    >
                      {{ item.dsName }}
                    </span>
                  </symptomDropdown>
                </span>
              </div>
            </template>
            <div v-else class="s_body">
              <Tabs v-model="tabsValue">
                <TabPane label="未选病因症状" name="1"></TabPane>
                <TabPane label="已选病因症状" name="2"></TabPane>
              </Tabs>
              <!-- class="symptom_name isError" -->
              <span
                @click="clickItem2(item)"
                class="symptom_name"
                v-for="item in tabsValue == '1' ? notSelected : selected"
                :key="item.dsId"
              >
                {{ item.dsName }}
              </span>
            </div>
            <last-symptom
              v-if="!filterDtId"
              v-model="showLastSymoptom"
              :patientCode="info.patientCode"
              @selectAll="selectAll"
              @removeSymptom="removeSymptom"
              @jump="jump"
              @clickItem="clickItem"
            />
          </div>
        </div>
      </div>
      <Spin class="u_spin" v-if="indexSpin.show" fix>
        <Icon type="ios-loading" size="30" class="demo-spin-icon-load"></Icon>
        <div>{{ indexSpin.text || "加载中..." }}</div>
      </Spin>
    </Modal>

    <Modal
      v-model="modal1"
      title="提示"
      footer-hide
      class-name="symptom-omission-selection"
    >
      <div class="title">漏选症状?</div>
      <div class="content">
        检测到该患者近期就诊存在
        <span class="t_read">{{ symptomNames }}</span>
        的症状，本次就诊可能漏选，请与患者确认是否仍存在以上症状，是否需要补选?
      </div>
      <div class="footer">
        <Button @click="addSymptom">补选</Button>
        <Button type="primary" @click="modal1 = false">不补选</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import etiologySelect from "./etiologySelect.vue";
import symptomSelect from "./symptomSelect.vue";
import { mapGetters } from "vuex";
import LastSymptom from "./lastSymptom.vue";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: "",
    },
    filterDtId: {
      //过滤的dtId
      type: String,
      default: "",
    },
    etiologyIdObj: {
      type: Object,
      default: () => {},
    },
  },
  components: { symptomSelect, LastSymptom, etiologySelect },
  computed: {
    ...mapGetters([
      "selectEtiology",
      "indexSpin",
      "diagnosticList",
      "diagnosticList2",
      "contradictDiagnosticList", //症状互斥列表
      "userInfo",
    ]),
    hasOption() {
      return this.selectList.filter((i) => i.etiologyList.length);
    },
    selected() {
      return this.hasOption.filter((j) =>
        j.etiologyList.some((i) => i.value && i.children.some((k) => k.label))
      );
    },
    notSelected() {
      return this.hasOption.filter(
        (j) =>
          !j.etiologyList.some(
            (i) => i.value && i.children.some((k) => k.label)
          )
      );
    },
  },
  watch: {
    info(newValue, oldValue) {
      this.again = false; //再次提交
      this.init();
    },
    value() {
      this.init();
    },
    showWin(value) {
      setTimeout(() => {
        this.focus();
      }, 1000);
    },
  },
  data() {
    return {
      etiologyIds: {},
      tabsValue: "1",
      showMore: false,
      activeSymptom: {},
      activeSymptom2: {},
      modal1: false,
      symptomNames: "",
      symptomList: [],
      again: false, //再次提交
      isJudgingSymptoms: false, //是否检测怀孕
      showLastSymoptom: false,
      showWin: false,
      selectList: [],
      quote: {
        ids: [],
        type: false,
      },
      sexAndAge: {
        sex: "",
        age: "",
      },
    };
  },
  methods: {
    getAllDsIdsData() {
      this.value.forEach((i) => {
        if (i.etiologyList) {
          let item = this.diagnosticList2.find((j) => j.dsId == i.dsId);
          if (item) {
            item.symptomChanges = i.symptomChanges;
            item.etiologyList = i.etiologyList;
            item.etiologyList.forEach((i) => {
              if (i.children.some((k) => k.label)) {
                this.etiologyIds[i.etiologyId] = this.common.clone(i);
              }
            });
          }
        }
      });
    },

    // 批量保存
    saveMrEtiologyPathogenesisExplainList() {
      let arr = [];
      this.selected.forEach((j) => {
        j.etiologyList.forEach((item) => {
          if (item.children.length) {
            arr.push({
              symptomNo: this.info.symptomNo, // 患者症状号
              patientCode: this.info.patientCode, // 患者编码
              bottomReason: item.children[0].label, // 病因底层原因
              bottomReasonJson: JSON.stringify(
                item.children.filter((i) => i.label).map((i) => i.label)
              ), // 病因底层原因JSON
              id: item.mrepId || null, // 主键(修改时传入)
              dialecticsId: item.dialecticsId, // 辨证Id
              etiologyId: item.etiologyId, // 病因Id
              pathogenesisId: item.pathogenesisId, // 病机Id
              symptomId: item.symptomId, // 症状Id
              noRelationSymptomName: item.noRelationSymptomName,
              symptomName: item.symptomName,
              isTypical: item.isTypical,
            });
          }
        });
      });

      if (arr.length > 0) {
        this.api2("saveMrEtiologyPathogenesisExplainList", {
          explainDTOS: arr,
        }).then((res) => {});
      }
    },

    getAllDsIdsData2() {
      let dsIds = this.selectList
        .filter((i) => !i.etiologyList.length)
        .map((i) => i.dsId)
        .join(","); // 症状集
      if (dsIds.length) {
        let obj = {
          dsIds,
          sysType: 0, // 系统类型  0云诊 1病案分析
          prescriptionNo: "", // 处方ID
        };

        this.api2("listSymptomEtiologyDialectical", obj).then((res) => {
          let list = this.formatData(res.result);
          this.setEtiologyIds(list);
        });
      }
    },
    setEtiologyIds(list) {
      this.selectList.forEach((i) => {
        if (!i.etiologyList.length) {
          i.etiologyList = list.filter((j) => j.symptomId == i.dsId);
        }
      });
    },

    formatData(obj) {
      let list = [];
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          const arr = obj[key];
          arr.forEach((i) => {
            if (!list.find((j) => j.etiologyId == i.etiologyId)) {
              if (this.etiologyIds[i.etiologyId]) {
                i.children = this.etiologyIds[i.etiologyId].children;
                i.value = true;
              } else {
                i.children = [];
                i.value = false;
              }
              list.push(i);
            }
          });
        }
      }
      return list;
    },
    addSymptom() {
      this.drzz(this.symptomList);
      this.modal1 = false;
    },
    focus() {
      if (this.showWin && this.$refs.symptomSelect) {
        this.$refs.symptomSelect.focus();
      }
    },

    clickMenu(item, value) {
      switch (value) {
        case 3:
          this.activeSymptom = item;
          this.activeSymptom2 = this.common.clone(item);
          break;
        case 4:
          item.Other = false;
          this.$refs.symptomSelect.clickSymptoms();
          this.etiologySelectClose();
          break;
      }
    },

    clickItem2(item) {
      if (this.selectEtiology && item.Other && this.notSelected.length) {
        this.$refs.etiologySelect.tip();
        let dsName = this.notSelected[0].dsName;
        this.$Message.warning(`请选好【${dsName}】的病因后，再进行其他操作`);
        this.clickMenu(this.notSelected[0], 3);
        this.$nextTick(() => {
          item.Other = false;
        });
        return;
      }
      this.activeSymptom = item;
      this.activeSymptom2 = this.common.clone(item);
    },

    etiologySelectChange(list) {
      list.forEach((item) => {
        if (item.value) {
          item.children = item.children.filter((i) => i.label);
          if (item.children.length) {
            this.etiologyIds[item.etiologyId] = item;
          }
        } else {
          delete this.etiologyIds[item.etiologyId];
        }

        this.selectList.forEach((i) => {
          if (i.symptomId != item.symptomId) {
            let e = i.etiologyList.find((j) => j.etiologyId == item.etiologyId);
            if (e) {
              e.value = item.value;
              e.children = item.children;
            }
          }
        });
      });
      this.activeSymptom.etiologyList = list;
    },

    clickItem(obj) {
      this.$refs.symptomSelect.clickItem(obj.id, obj.type);
    },
    delSymptom(item) {
      item.Other = false;
      this.$refs.symptomSelect.clickSymptoms();
      this.etiologySelectClose();
    },
    jump(res) {
      this.$refs.symptomSelect.jump(res.id, res.type);
    },
    removeSymptom(ids) {
      this.quote = {
        ids: [],
        type: false,
      };
      this.$refs.symptomSelect.removeSymptom(ids);
    },
    selectAll(ids) {
      this.quote = {
        ids: ids,
        type: false,
      };
      setTimeout(() => {
        this.getAllDsIdsData2();
      }, 100);
    },
    openWin() {
      this.init();
      this.showLastSymoptom = false;
      this.showWin = true;
    },
    close() {
      this.showWin = false;
      this.showMore = false;
      this.etiologySelectClose();
    },
    etiologySelectClose() {
      if (this.$refs.etiologySelect) {
        this.$refs.etiologySelect.close();
      }
    },
    clear() {
      this.etiologyIds = {};
      this.selectList.forEach((i) => (i.Other = false));
      this.etiologySelectClose();
      setTimeout(() => {
        this.$refs.symptomSelect.clickSymptoms();
      }, 100);
    },
    async send() {
      if (this.selectEtiology && this.notSelected.length) {
        this.clickMenu(this.notSelected[0], 3);
        let dsNames = this.notSelected.map((i) => i.dsName).join(",");
        this.$Message.warning(`【${dsNames}】未选病因，请选好病因后再提交`);
        this.$refs.etiologySelect.tip();
        return;
      }

      if (!this.filterDtId) {
        //如果有过滤类型的话 就不验证症状的必选项等
        if (!this.$refs.symptomSelect.must()) return; //验证必选项
        if (
          !this.isJudgingSymptoms &&
          this.info.sex == "女" &&
          this.info.patientAge > 8 &&
          this.info.patientAge < 60
        ) {
          let obj = {
            patientCode: this.info.patientCode,
            patientAge: this.info.patientAge,
            fsex: this.info.sex,
            detailList: this.selectList.map((i) => {
              return { dsId: i.dsId };
            }),
          };
          this.$store.commit("openSpin");
          try {
            let judgingSymptoms_res = await this.api2("judgingSymptoms", obj);
            this.$store.commit("closeSpin");
            this.isJudgingSymptoms = true;
            if (
              judgingSymptoms_res.result &&
              judgingSymptoms_res.result.isRedMessage == "是"
            ) {
              let content = `<p>患者是13-60岁女性，无明显未孕症状，注意确认是否可能怀孕？若无问题，请继续提交。</p>`;
              this.$store.commit("setN_tip", {
                title: "小贴士",
                content,
              });
              return;
            }
          } catch (error) {
            this.$store.commit("closeSpin");
            console.log(error);
          }
        }

        if (!this.again) {
          try {
            let res = await this.getSpecialSymptomsRemind();
            if (res.result.length) return;
          } catch (error) {
            console.log(error);
          }
        }

        // 保存功能已经放在开方页实现了
        // this.saveMrEtiologyPathogenesisExplainList();
      }
      this.$emit("input", this.selectList);
      this.$emit("submit", this.selectList);
      this.showWin = false;
      this.showMore = false;
      this.etiologySelectClose();
    },

    // 特殊症状提醒 新
    getSpecialSymptomsRemind() {
      this.$store.commit("openSpin");
      let obj = {
        symptomCode: this.selectList.map((i) => i.dsId).join("|"), //	本次症状Id 格式:444|9666|999
        patientCode: this.info.patientCode, //	患者编码
      };
      return new Promise((resolve, reject) => {
        this.api2("getSpecialSymptomsRemind", obj)
          .then((res) => {
            // 测试
            // res = {
            //   result: [
            //     {
            //       DS_ID: 50377,
            //       DS_Name: "贫血",
            //     },
            //     {
            //       DS_ID: 50408,
            //       DS_Name: "大便一日数次(长期)",
            //     }
            //   ],
            // };

            let data = res.result;
            this.$store.commit("closeSpin");
            this.again = true;
            if (data.length) {
              let ds_names = data.map((i) => i.DS_Name).join("、");
              this.symptomList = data;
              this.symptomNames = ds_names;
              this.modal1 = true;
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // 特殊症状提醒 旧
    getSpecialSymptomsRemind2() {
      this.$store.commit("openSpin");
      let obj = {
        symptomCode: this.selectList.map((i) => i.dsId).join("|"), //	本次症状Id 格式:444|9666|999
        patientCode: this.info.patientCode, //	患者编码
      };
      return new Promise((resolve, reject) => {
        this.api2("getSpecialSymptomsRemind", obj)
          .then((res) => {
            let data = res.result;
            this.$store.commit("closeSpin");
            this.again = true;

            if (data.length) {
              let onOk = null;
              let ds_names = data.map((i) => i.DS_Name).join("、");
              let content = "";
              let okText = "";
              if (this.contradictValidate2(data)) {
                content = `<p><span class="utip_title">漏选症状？</span></p>
                            <p>患者可能有<span class="t_read">${ds_names}</span>的症状漏选。</p>
                            <p><i class="iconfont icon-warn"></i>请和患者沟通确认，是否须补选</p>`;
              } else {
                this.drzz(data);
                content = `<p><span class="utip_title">漏选症状？</span></p>
                            <p>患者可能有<span class="t_read">${ds_names}</span>的症状漏选，AI已先补选。</p>
                            <p><i class="iconfont icon-warn"></i>请和患者沟通确认，如不需要请删除</p>`;
              }
              this.$store.commit("setN_tip", {
                confirm: true,
                title: "",
                content,
                onOk,
                okText,
              });
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    contradictValidate2(list) {
      let ids = list.map((i) => i.DS_ID);
      let arr = this.diagnosticList2.filter((i) => ids.includes(i.dsId));
      let dtIds = arr.map((i) => i.parentId);
      let dsIds = arr.map((i) => i.dsId);
      let dtId2s = this.contradictDiagnosticList.SubType.filter((i) =>
        dtIds.includes(i.dtId)
      ).map((i) => i.dtId2);
      let dtList = this.selectList.filter((i) => dtId2s.includes(i.parentId));

      let dsId2s = this.contradictDiagnosticList.Symptom.filter((i) =>
        dsIds.includes(i.dsId)
      ).map((i) => i.dsId2);
      let dsList = this.selectList.filter((i) => dsId2s.includes(i.dsId));
      return dtList.length || dsList.length;
    },

    //冲突症状检测  返回冲突症状列表
    contradictValidate() {
      let list = u_common.clone(this.selectList);
      let arr = [];
      this.contradictDiagnosticList.SubType.forEach((i) => {
        let symptom1 = list.find((j) => j.parentId == i.dtId);
        let symptom2 = list.find((j) => j.parentId == i.dtId2);
        if (symptom1 && symptom2) {
          let item = arr.find((i) => i == symptom1);
          if (!item) arr.push(symptom1);
          let item2 = arr.find((i) => i == symptom2);
          if (!item2) arr.push(symptom2);
        }
      });

      this.contradictDiagnosticList.Symptom.forEach((i) => {
        let symptom1 = list.find((j) => j.dsId == i.dsId);
        let symptom2 = list.find((j) => j.dsId == i.dsId2);
        if (symptom1 && symptom2) {
          let item = arr.find((i) => i == symptom1);
          if (!item) arr.push(symptom1);
          let item2 = arr.find((i) => i == symptom2);
          if (!item2) arr.push(symptom2);
        }
      });
      return arr;
    },

    // 导入症状
    drzz(arr) {
      this.quote = {
        type: true,
        ids: arr.map((i) => i.DS_ID + ""),
      };
    },

    // 初始化
    init() {
      this.etiologyIds = this.etiologyIdObj;

      this.diagnosticList2.forEach((i) => {
        i.etiologyList.forEach((j) => {
          i.children = [];
        });
      });
      this.isJudgingSymptoms = false;
      this.selectList = this.value;

      if (this.value.length) {
        this.quote = {
          ids: this.value.map((i) => i.dsId + ""),
          type: false,
        };
      } else {
        this.quote = {
          ids: [],
          type: false,
        };
      }

      this.sexAndAge = {
        sex: this.info.sex == "女" ? 2 : 1,
        age: this.info.patientAge,
      };
      this.getAllDsIdsData();
      this.getAllDsIdsData2();
    },
  },
  created() {
    this.again = false; //再次提交
    this.init();
  },
  mounted() {
    // 视觉改变触发
    $(document).on("visibilitychange", (e) => {
      if (e.target.visibilityState === "visible") {
        this.focus();
      }
    });
  },
};
</script>

<style lang="scss">
.condition.symptom_select_input {
  min-height: 0;
}

.symptom_select_input {
  min-height: 26px;

  & > .text {
    cursor: pointer;
  }

  .mk_popup {
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: auto;
  }

  .btn_group {
    position: absolute;
    z-index: 12;
    right: 10px;
    top: 8px;

    .ivu-btn {
      margin-left: 15px;
    }
  }

  .symptom_name {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    font-weight: 400;
    font-size: 14px;
    background: #fbf7f4;
    margin: 0 10px 10px 0;
    cursor: pointer;

    &.isError {
      background: #f00;
      color: #fff;
    }

    &.symptom_name_remove:hover {
      background: url(../assets/images/x.png) no-repeat right top #dbceba;
    }
  }

  .ivu-modal-header-inner {
    width: auto;
    font-size: 16px;
  }

  .ivu-modal-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
  }
  .pathent-info {
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
    span {
      padding-right: 15px;
    }
    .iconfont {
      margin-right: 10px;
    }
    .pathent-info-name {
      font-weight: bold;
      font-size: 15px;
    }
  }

  .ivu-modal-body {
    padding: 0;
    overflow: hidden;
    display: flex;
    top: 48px;
  }

  .s_s_i_list {
    position: relative;
    flex-shrink: 0;
    height: 100%;
    padding: 15px 15px 15px 0;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    .s_s_i_list_body {
      background: #fff;
      border-radius: 10px;
      // padding: 15px;
      height: 100%;
      display: flex;
    }

    .s_body {
      height: 100%;
      overflow-y: auto;
      .mk {
        margin-bottom: 10px;
      }
    }
    .mk_title {
      margin-bottom: 8px;
    }

    .s_header {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
    }

    .s_header .ivu-btn {
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .right {
    width: 290px;
    position: relative;
    padding: 15px;
    .title_right {
      padding-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }
  }
  .s_header2 {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 15px;
    background: #f9f9fb;
    line-height: 2em;
  }
  .ivu-tabs-nav {
    width: 100%;
    :deep {
      .ivu-tabs-tab {
        width: 100%;
      }
    }
  }
  .ivu-modal-content {
    background: #fff;
  }
}
.symptom-omission-selection {
  .title {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .content {
    line-height: 2em;
    margin-bottom: 20px;
  }
  .footer {
    text-align: right;
    .ivu-btn {
      margin-left: 20px;
    }
  }
  .t_read {
    color: #f00;
  }
}
</style>
